<template>
  <v-card
    class="mx-auto"
    elevation="0"
    height="100%"
    color="reform_primary_light_2"
  >
    <v-card-title>
      <v-container fluid>
        <v-row justify="center" align="center" no-gutters>
          <v-col cols="12" class="text-center">
            <v-img
              v-if="
                hospLogo !== undefined && hospLogo !== null && hospLogo !== ''
              "
              class="ml-auto mr-auto"
              contain
              :src="'https://www.drrefer.net/api/pic/' + hospLogo"
              width="200px"
              height="100px"
            >
            </v-img>
            <v-icon size="40" v-else color="red">mdi-hospital-box</v-icon>
          </v-col>
          <v-col cols="12" class="text-center text-h6 font-weight-bold mt-3">
            {{ title }}
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-list nav color="reform_primary_light_2" class="pl-5 pr-5">
      <v-list-item-group v-model="curMenu" color="reform_primary" mandatory>
        <!-- 1번 -->
        <v-list-group
          color="reform_primary"
          v-for="(item, i) in items"
          :key="i"
          :value="i === curMenu ? true : false"
          @click="goURL(item.items[0].to, item.items[0].menu)"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title" class="sidebar-title"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list nav rounded color="reform_primary_light_2">
            <v-list-item-group v-model="selectItem[i]" mandatory>
              <v-list-item
                v-for="(child, j) in item.items"
                :key="j"
                active-class="active-list-menu"
                @click="goURL(child.to, child.menu)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="child.title"
                    class="pl-10 sidebar-content"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-list-group>
        <!-- 1번끝 -->
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    selectItem: [0, 0, 0],
    curMenu: 0,
    items: [
      {
        items: [
          {
            title: "수신 환자 리스트",
            to: "/v/mypage/request/0",
            menu: [0, 0],
          },
          {
            title: "전체 수신 환자 리스트",
            to: "/v/mypage/request/1",
            menu: [0, 1],
          },
          {
            title: "발신 환자 리스트",
            to: "/v/mypage/request/2",
            menu: [0, 2],
          },
          {
            title: "최근 진료 의뢰 받은 병원",
            to: "/v/mypage/request/3",
            menu: [0, 3],
          },
          {
            title: "최근 진료 의뢰한 병원",
            to: "/v/mypage/request/4",
            menu: [0, 4],
          },
        ],
        title: "진료 의뢰 현황",
      },
      {
        items: [
          { title: "발신 쪽지함", to: "/v/mypage/sentnoteList", menu: [1, 0] },
          { title: "수신 쪽지함", to: "/v/mypage/inboxnoteList", menu: [1, 1] },
          { title: "쪽지 보내기", to: "", menu: [1, 2] },
        ],
        title: "쪽지 관리",
      },
      {
        items: [
          {
            title: "회원정보 수정",
            to: "/v/mypage/modifymember",
            menu: [2, 0],
          },
          {
            title: "검색정보 관리",
            to: "/v/mypage/modifySearch",
            menu: [2, 1],
          },
          { title: "소개정보 관리", to: "/v/mypage/prmanage", menu: [2, 2] },
          { title: "비밀번호 변경", to: "/v/mypage/changePw", menu: [2, 3] },
          { title: "부계정 관리", to: "/v/mypage/sub", menu: [2, 4] },
          { title: "회원탈퇴", to: "/v/mypage/draw", menu: [2, 5] },
        ],
        title: "회원 정보",
      },
      {
        items: [
          {
            title: "온라인 상담 설정",
            to: "/v/mypage/appointmentConfig",
            menu: [3, 0],
          },
          {
            title: "상담요청 조회",
            to: "/v/mypage/appointmentList",
            menu: [3, 1],
          },
        ],
        title: "온라인 상담",
      },
    ],
    title: "",
    logo: "",
  }),
  created() {
    this.loadHospNm();
    this.setStatus();
  },
  computed: {
    ...mapGetters("join", ["GET_HOSP_NM", "GET_HOSP_LOGO"]),
    ...mapState("join", ["hospNm", "hospLogo"]),
  },
  methods: {
    ...mapActions("join", ["SELECT_HOSP_NM"]),

    sendEvent(item) {
      if (
        this.selectItem === undefined ||
        this.selectItem === null ||
        this.selectItem === ""
      ) {
        return;
      }
      if (item.title === "진료 의뢰 현황") {
        this.$router
          .push("/v/mypage/request/" + this.selectItem)
          .catch(() => {});
      } else if (item.title === "쪽지 관리") {
        if (this.selectItem === 2) {
          const sendAppheaderItem = {
            method: "writeMessage",
            item: "noteList",
          };
          this.$emit("send-app-header-method", sendAppheaderItem);
        } else if (this.selectItem === 0) {
          this.$router.push("/v/mypage/sentnoteList").catch(() => {});
        } else if (this.selectItem === 1) {
          this.$router.push("/v/mypage/inboxnoteList").catch(() => {});
        }
      } else if (item.title === "회원 정보") {
        if (this.selectItem === 0) {
          this.$router.push("/v/mypage/modifymember").catch(() => {});
        } else if (this.selectItem === 1) {
          this.$router.push("/v/mypage/modifySearch").catch(() => {});
        } else if (this.selectItem === 2) {
          this.$router.push("/v/mypage/prmanage").catch(() => {});
        } else if (this.selectItem === 3) {
          this.$router.push("/v/mypage/appointmentList").catch(() => {});
        } else if (this.selectItem === 4) {
          this.$router.push("/v/mypage/changePw").catch(() => {});
        } else if (this.selectItem === 5) {
          this.$router.push("/v/mypage/sub").catch(() => {});
        } else if (this.selectItem === 6) {
          this.$router.push("/v/mypage/draw").catch(() => {});
        }
      }
    },
    setStatus() {
      let text = this.$route.path;
      if (text.includes("request/0")) {
        this.curMenu = 0;
        this.selectItem[this.curMenu] = 0;
      } else if (text.includes("request/1")) {
        this.curMenu = 0;
        this.selectItem[this.curMenu] = 1;
      } else if (text.includes("request/2")) {
        this.curMenu = 0;
        this.selectItem[this.curMenu] = 2;
      } else if (text.includes("request/3")) {
        this.curMenu = 0;
        this.selectItem[this.curMenu] = 3;
      } else if (text.includes("request/4")) {
        this.curMenu = 0;
        this.selectItem[this.curMenu] = 4;
      } else if (text.includes("sentnoteList")) {
        this.curMenu = 1;
        this.selectItem[this.curMenu] = 0;
      } else if (text.includes("inboxnoteList")) {
        this.curMenu = 1;
        this.selectItem[this.curMenu] = 1;
      } else if (text.includes("modifymember")) {
        this.curMenu = 2;
        this.selectItem[this.curMenu] = 0;
      } else if (text.includes("modifySearch")) {
        this.curMenu = 2;
        this.selectItem[this.curMenu] = 1;
      } else if (text.includes("prmanage")) {
        this.curMenu = 2;
        this.selectItem[this.curMenu] = 2;
      } else if (text.includes("changePw")) {
        this.curMenu = 2;
        this.selectItem[this.curMenu] = 3;
      } else if (text.includes("sub")) {
        this.curMenu = 2;
        this.selectItem[this.curMenu] = 4;
      } else if (text.includes("draw")) {
        this.curMenu = 2;
        this.selectItem[this.curMenu] = 5;
      } else if (text.includes("appointmentConfig")) {
        this.curMenu = 3;
        this.selectItem[this.curMenu] = 0;
      } else if (text.includes("appointmentList")) {
        this.curMenu = 3;
        this.selectItem[this.curMenu] = 1;
      }
    },
    goURL(url, menu) {
      if (url === "") {
        const sendAppheaderItem = {
          method: "writeMessage",
          item: "noteList",
        };
        this.$emit("send-app-header-method", sendAppheaderItem);
      } else {
        this.curMenu = menu[0];
        this.selectItem[menu[0]] = menu[1];
        this.$router.push(url).catch(() => {});
      }
    },
    async loadHospNm() {
      try {
        await this.SELECT_HOSP_NM().then(() => {
          this.title = this.hospNm;
          this.logo = this.hospLogo;
        });
      } catch {}
    },
  },
};
</script>

<style>
.active-list-menu {
  background-color: white;
}

.active-list-menu.theme--light.v-list-item--active::before {
  opacity: 0;
}

.sidebar-title { 
  font-size: 20px; 
  font-weight: 500;
}

.sidebar-content {
  font-size: 17px; 
  font-weight: 500;
}



</style>
