<template>
  <v-container fluid class="ma-0 pa-0 mypage_container">
    <v-row no-gutters class="fill-height">
      <v-col cols="12" xl="2" lg="2" class="sidebar-col">
        <request-sidebar
          ref="sidebar"
          @click-Menu="changeSelectMenu"
          @click-list="changeSelectItem"
          @send-app-header-method="sendAppheaderMethod"
        />
      </v-col>
      <v-col cols="12" xl="10" lg="10" class="view-col">
        <v-main>
          <router-view
            ref="requestList"
            @send-app-header-method="sendAppheaderMethod"
            @sidebar-update-method="sidebarUpdateMethod"
          />
        </v-main>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RequestSidebar from "../components/request/RequestSidebar.vue";
export default {
  components: {
    RequestSidebar,
  },
  data() {
    return {
      selectItem: 0,
      title: 0,
    };
  },
  created() {},
  methods: {
    async changeSelectItem(item) {
      // console.log("확인사항 ", item);
      if (this.title === 0) {
        if (item === 1) {
          this.selectItem = 4;
        } else {
          if (item === 0) {
            this.selectItem = item;
          } else {
            this.selectItem = item - 1;
          }
          await this.$refs.requestList.reload(this.selectItem);
        }
      }
    },
    sendAppheaderMethod(sendAppheaderItem) {
      this.$emit("send-app-header-method", sendAppheaderItem);
    },
    changeSelectMenu(title) {
      if (title === "진료 의뢰 현황") {
        this.title = 0;
      } else if (title === "쪽지 관리") {
        this.title = 1;
      } else if (title === "회원 정보") {
        this.title = 2;
      }
    },
    sidebarUpdateMethod() {
      this.$refs.sidebar.loadHospNm();
    },
    // logoLeft() {
    //   // document.querySelector(".header-logo-login").style.display = "relative";
    //   // document.querySelector(".header-logo-login").style.left = "-180px";
    //   $(".header-logo-login").css("display", "relative");
    //   $(".header-logo-login").css("left", "-180px");
    // },
  },
  beforeDestroy() {
    // document.querySelector(".header-logo-login").style.display = "normal";
    // document.querySelector(".header-logo-login").style.left = "0px";
  },
};
</script>

<style>
.mypage_container {
  border-top: 1px solid gainsboro;
}

@media screen and (max-width: 1782px) {
  .sidebar-col {
    min-width: 20% !important;
  }
  .view-col {
    max-width: 80% !important;
  }
}

@media screen and (max-width: 1486px) {
  .sidebar-col {
    min-width: 23% !important;
  }
  .view-col {
    max-width: 77% !important;
  }
}

@media screen and (max-width: 1288px) {
  .sidebar-col {
    min-width: 24% !important;
  }
  .view-col {
    max-width: 76% !important;
  }
}

@media screen and (max-width: 1264px) {
  .sidebar-col {
    min-width: initial !important;
  }
  .view-col {
    max-width: initial !important;
  }
}

</style>
